<template>
  <div>
    home page
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>

</style>
