import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#cbd5cc',
        secondary: '#eaebea',
        button: '#59756f',
      },
    },
  },
});
