export const START_LOADING = 'startLoading';
export const STOP_LOADING = 'stopLoading';
export const SET_CATEGORIES = 'setCategories';
export const SET_PRODUCTS = 'setProducts';
export const SET_SELECTED_PRODUCT = 'setSelectedProduct';
export const SET_SEARCH_RESULTS = 'setSearchResults';
export const ADD_PRODUCT_TO_CART = 'addProductToCart';
export const REMOVE_PRODUCT_FROM_CART = 'removeProductFromCart';
export const UPDATE_PRODUCT_QUANTITY_IN_CART = 'updateProductInCart';
export const CREATE_CART = 'createCart';
export const UPDATE_INSTRUCTIONS_IN_CART = 'updateInstructionsInCart';
export const SET_POSTS = 'setPosts';
export const SET_SELECTED_POST = 'setSelectedPost';
